// npm
import React, { memo, useRef } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import {
  Box,
  Container,
  Grid,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import { alpha } from '@mui/system'
import striptags from 'striptags'
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
} from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'
import { Swiper, SwiperSlide } from 'swiper/react'
// src
import DirectionButton from '@atoms/buttons/DirectionButton'
import Subtitle from '@molecules/headings/Subtitle'
import Quote from 'images/icons/quote.svg'
import NavArrow from 'images/nav-arrow.svg'

interface Props {
  subtitle: string
}

const SwiperContainer = styled(Box)(({ theme }) => ({
  ['.quotes-swiper']: {
    position: 'relative',
  },
  ['.swiper-pagination-bullets.swiper-pagination-horizontal']: {
    width: 'auto',
  },
  ['.swiper-pagination-bullet']: {
    width: '8px',
    height: '8px',
    background: 'rgba(38,35,35,.2)',
    opacity: 1,
    position: 'relative',
    margin: '0 10px !important' as any,
    ['&:before']: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate3d(-50%,-50%,0)',
      content: '""',
      display: 'block',
      width: '22px',
      height: '22px',
      background: 'none',
      borderRadius: '50%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: alpha(theme.palette.primary.main, 0),
      transition: theme.transitions.create(['border-color']),
    },
    ['&.swiper-pagination-bullet-active']: {
      ['&:before']: {
        borderColor: alpha(theme.palette.primary.main, 0.5),
      },
    },
  },
}))

const SlideImage = styled(GatsbyImage)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'block',
    height: '0',
    paddingTop: '60%',
  },
}))

const NavContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}))
const QuoteIcon = styled(Quote)(({ theme }) => ({
  width: '57px',
  opacity: 0.4,
  [theme.breakpoints.down('md')]: {
    margin: '0 auto',
    display: 'block',
  },
}))

const Arrow = styled(NavArrow)(({ theme }) => ({
  width: '18px',
  [theme.breakpoints.up('md')]: {
    width: '19px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '20px',
  },
}))

const ContentSectionAutoTestimonials = ({ subtitle }: Props) => {
  const theme = useTheme()
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade])

  return (
    <StaticQuery<Queries.TestimonialsQuery>
      query={graphql`
        query Testimonials {
          allDatoCmsTestimonial(limit: 10) {
            edges {
              node {
                id
                customerName
                customerLocation
                smallImage: image {
                  gatsbyImageData(
                    width: 500
                    height: 300
                    layout: CONSTRAINED
                    imgixParams: { ar: "5:3", fit: "crop", crop: "focalpoint" }
                  )
                }
                largeImage: image {
                  gatsbyImageData(
                    width: 320
                    layout: CONSTRAINED
                    imgixParams: {
                      ar: "320:420"
                      fit: "crop"
                      crop: "focalpoint"
                    }
                  )
                }
                quoteNode {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <Box
            py={{
              xs: 4,
              lg: 12,
            }}
            position="relative"
            bgcolor="common.white"
          >
            <Container maxWidth="xl">
              <Subtitle
                text={subtitle}
                color="primary.main"
                borderType="leftToBottom"
                breakpoint="md"
                typographyProps={{ component: 'h2' }}
              />
            </Container>
            <SwiperContainer>
              <Swiper
                onInit={(swiper) => {
                  // @ts-ignore
                  swiper.params.navigation.prevEl = prevRef.current
                  // @ts-ignore
                  swiper.params.navigation.nextEl = nextRef.current
                  swiper.navigation.init()
                  swiper.navigation.update()
                }}
                pagination={{
                  el: '.swiper-pagination-container',
                }}
                slidesPerView={1}
                observer={true}
                loop={true}
                spaceBetween={0}
                observeParents={true}
                autoHeight={true}
                autoplay={{
                  delay: 7000,
                }}
                effect="fade"
                speed={1000}
                className="quotes-swiper"
              >
                {data.allDatoCmsTestimonial.edges.map((item: any) => {
                  const slide: Queries.DatoCmsTestimonial = item.node

                  const images = withArtDirection(
                    // @ts-ignore
                    getImage(d.largeImage.gatsbyImageData),
                    [
                      {
                        media: `(max-width: ${theme.breakpoints.values.md}px)`,
                        image: getImage(slide.smallImage.gatsbyImageData),
                      },
                    ]
                  )
                  return (
                    <SwiperSlide key={slide.id}>
                      <Container
                        maxWidth="xl"
                        sx={{ backgroundColor: 'white' }}
                      >
                        <Grid container spacing={4}>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            lg={3}
                            sx={{ overflow: 'hidden' }}
                          >
                            <SlideImage image={images} alt="" />
                          </Grid>
                          <Grid item md={8} lg={9} pb={{ md: 7, lg: 9 }}>
                            <QuoteIcon />
                            <Typography
                              variant="quote"
                              component="div"
                              mt={2}
                              mb={{
                                xs: 2,
                                md: 0,
                              }}
                              textAlign={{
                                xs: 'center',
                                md: 'left',
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `"${striptags(
                                    slide.quoteNode.childMarkdownRemark.html
                                  )}"`,
                                }}
                              />
                            </Typography>
                            <Box
                              sx={{
                                width: '20px',
                                height: '1px',
                                background: 'rgba(40,36,36,.2)',
                                my: 3,
                                display: {
                                  xs: 'none',
                                  md: 'block',
                                },
                              }}
                            />
                            <Typography
                              variant="quotee"
                              component="div"
                              color="text.primary"
                              textAlign={{
                                xs: 'center',
                                md: 'left',
                              }}
                            >
                              <strong>-&nbsp;{slide.customerName}</strong>,{' '}
                              {slide.customerLocation}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Container>
                    </SwiperSlide>
                  )
                })}
                <Container
                  maxWidth="xl"
                  sx={{ position: 'relative', zIndex: 20 }}
                >
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                      md: {
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={8}
                      lg={9}
                      sx={{ position: 'relative' }}
                    >
                      <NavContainer>
                        <div className="swiper-pagination-container" />
                        <Box ml={{ md: 'auto' }} mt={{ xs: 3, md: 0 }}>
                          <DirectionButton
                            direction="left"
                            aria-label="previous testimonial"
                            ref={prevRef}
                          />
                          <DirectionButton
                            direction="right"
                            aria-label="next testimonial"
                            ref={nextRef}
                          />
                        </Box>
                      </NavContainer>
                    </Grid>
                  </Grid>
                </Container>
              </Swiper>
            </SwiperContainer>
          </Box>
        )
      }}
    />
  )
}

export default memo(ContentSectionAutoTestimonials)
